import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { createStyles, Theme, CircularProgress, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Select, FormControl, InputLabel, MenuItem, Backdrop, makeStyles } from '@material-ui/core';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState, useContext } from 'react';
import { DivergenciaType } from '../DivergenciaDetailTypes';
import { DivergenceContext, DivergenceContextType } from '../../../../../context';
import { upsertAnalise } from '../../mutations'

const AnaliseQuery = loader(
    '../../queries/getAnaliseByDivergencia.gql'
);

const SetorResponsavelQuery = loader(
    '../../queries/getAllSetorResponsavel.gql'
);

const TipoPendenciaQuery = loader(
    '../../queries/getAllTipoPendenciaBySetor.gql'
);

const CausaRaizQuery = loader(
    '../../queries/getAllCausaRaizByPendencia.gql'
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 99999,
        color: "#fff",
      },
    })
);

const SetorPendenciaCausaRaiz: FunctionComponent<{
    divergencia: DivergenciaType;
}> = ({ divergencia }) => {

    const { showSetor, setShowSetor, setFeedbackAnalise } = useContext<DivergenceContextType>(DivergenceContext);

    const classes = useStyles();

    const idDivergencia = divergencia?.id_divergencia

    const [setorSelecionado, setSetorSelecionado] = useState(0)
    const [pendenciaSelecionada, setPendenciaSelecionada] = useState(0)
    const [causaSelecionada, setCausaSelecionada] = useState(0)

    const handleCloseSetor = () => {
        setShowSetor(false)
        setSetorSelecionado(0)
        setPendenciaSelecionada(0)
        setCausaSelecionada(0)
    }

    const handleSelectSetor = (e: any) => {
        setPendenciaSelecionada(0)
        setCausaSelecionada(0)
        setSetorSelecionado(e.target.value as number)
        getPendencias()
    }

    const handleSelectPendencia = (e: any) => {
        setCausaSelecionada(0)
        setPendenciaSelecionada(e.target.value as number)
        getCausas()
    }

    const handleSelectCausa = (e: any) => {
        setCausaSelecionada(e.target.value as number)
    }

    const { loading: loadingAnalise } = useQuery<{
        getAnaliseByDivergencia: {
            id_analise_chamados: number;
            id_divergencia: number;
            id_setor_responsavel: number;
            id_tipo_pendencia: number;
            id_causa_raiz: number;
        };
    }>(AnaliseQuery,  { variables: { id_divergencia: idDivergencia }});

    const { loading: loadingSetor, data: dataSetorResponsavel } = useQuery<{
        getAllSetorResponsavel: {
            id_setor_responsavel: number;
            setor_responsavel: string;
        }[];
    }>(SetorResponsavelQuery);
    
    const [getPendencias, { loading: loadingPendencia, data: dataTipoPendencia }] = useLazyQuery<{
        getAllTipoPendenciaBySetor: {
            id_tipo_pendencia: number;
            tipo_pendencia: string;
        }[];
    }>(TipoPendenciaQuery, { variables: { id_setor_responsavel: setorSelecionado }});
    
    const [getCausas, { loading: loadingCausa, data: dataCausaRaiz }] = useLazyQuery<{
        getAllCausaRaizByPendencia: {
            id_causa_raiz: number;
            causa_raiz: string;
        }[];
    }>(CausaRaizQuery, { variables: { id_tipo_pendencia: pendenciaSelecionada } });

    const [handleProcessar, { loading: loadingSave }] = useMutation(
        upsertAnalise,
        {
            variables: {
                id_divergencia: idDivergencia,
                id_setor_responsavel: setorSelecionado,
                id_tipo_pendencia: pendenciaSelecionada,
                id_causa_raiz: causaSelecionada
            },
            onCompleted(data) {
                setSetorSelecionado(0)
                setPendenciaSelecionada(0)
                setCausaSelecionada(0)
                setShowSetor(false)
                setFeedbackAnalise({
                    message: 'Divergência atualizada com sucesso!',
                    show: true,
                    type: "success",
                });
            },
            onError(error) {
                setFeedbackAnalise({
                    message: 'Erro ao atualizar a divergência!',
                    show: true,
                    type: "error",
                });
            },
        }
      );

    return (
        <> 
        { loadingSave ? 
        
        <Backdrop className={classes.backdrop} open={loadingSave}>
          <CircularProgress color="inherit" />
        </Backdrop> 
        
        : 

        <Dialog
                fullWidth
                open={showSetor}
                onClose={() => setShowSetor(false)}
            >
                <DialogTitle>Análise Chamados Corte</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                    <FormControl fullWidth>
                        <InputLabel>
                            Selecione o setor responsável
                        </InputLabel>
                        <Select
                            disabled={!dataSetorResponsavel || dataSetorResponsavel.getAllSetorResponsavel.length === 0 || loadingAnalise || loadingSetor}
                            onChange={(e) => handleSelectSetor(e)}
                        >
                            <MenuItem value=""></MenuItem>
                            {dataSetorResponsavel?.getAllSetorResponsavel.map((item: any) => {
                                return (
                                    <MenuItem
                                        key={item.id_setor_responsavel}
                                        value={item.id_setor_responsavel}
                                    >
                                        {item.setor_responsavel}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>
                            Selecione o tipo da pendência
                        </InputLabel>
                        <Select
                            disabled={(setorSelecionado === 0 && (!dataTipoPendencia || dataTipoPendencia.getAllTipoPendenciaBySetor.length === 0)) || loadingPendencia}
                            onChange={(e) => handleSelectPendencia(e)}
                        >
                            <MenuItem value=""></MenuItem>
                            {dataTipoPendencia?.getAllTipoPendenciaBySetor.map((item: any) => {
                                return (
                                    <MenuItem
                                        key={item.id_tipo_pendencia}
                                        value={item.id_tipo_pendencia}
                                    >
                                        {item.tipo_pendencia}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>
                            Selecione a causa raiz
                        </InputLabel>
                        <Select
                            disabled={(pendenciaSelecionada === 0 && (!dataCausaRaiz || dataCausaRaiz.getAllCausaRaizByPendencia.length === 0)) || loadingCausa}
                            onChange={(e) => handleSelectCausa(e)}
                        >
                            <MenuItem value=""></MenuItem>
                            {dataCausaRaiz?.getAllCausaRaizByPendencia.map((item: any) => {
                                return (
                                    <MenuItem
                                        key={item.id_causa_raiz}
                                        value={item.id_causa_raiz}
                                    >
                                        {item.causa_raiz}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        className="primary500"
                        onClick={() => handleCloseSetor()}
                    >
                        <span className="text-transform-initial bold">
                            Cancelar
                        </span>
                    </Button>
                    <Button 
                        variant="contained"
                        className="backgroundPrimary500"
                        onClick={() => handleProcessar()}
                    >
                        <span 
                            className="text-transform-initial neutral000 bold"
                        >
                            Confirmar
                        </span>
                    </Button>
                </DialogActions>
            </Dialog>
        }
            
        </>
    );
};

export default SetorPendenciaCausaRaiz;
