import { gql } from '@apollo/client'

export const upsertAnalise = gql`
  mutation upsertAnaliseChamados(
    $id_divergencia: Int!
    $id_setor_responsavel: Int!
    $id_tipo_pendencia: Int!
    $id_causa_raiz: Int!
  ) {
    upsertAnaliseChamados (
      id_divergencia: $id_divergencia
      id_setor_responsavel: $id_setor_responsavel
      id_tipo_pendencia: $id_tipo_pendencia
      id_causa_raiz: $id_causa_raiz
    ) {
      id_divergencia
      id_setor_responsavel
      id_tipo_pendencia
      id_causa_raiz
    }
  } 
`